<template lang="pug">
.ma-4
  v-stepper(v-model="step", dense, non-linear)
    v-stepper-header(elevation="0")
      v-stepper-step.pa-3(
        step="1"
        editable
      ) Configurações

      v-divider
      v-stepper-step.pa-3(
        v-if="$route.params.id > 0",
        step="2",
        editable
      ) SQL

      v-divider
      v-stepper-step.pa-3(
        v-if="$route.params.id > 0",
        step="3"
        editable
      )
        | Clientes

    v-stepper-items
      v-stepper-content.pa-0.pt-1(step="1")
        v-card(style="height: calc(100vh - 225px); overflow-y: auto")
          v-card-text.pb-0.pt-4
            v-row
              v-col.pt-0.pb-0(cols="7", md="7")
                v-form(ref="form")
                  b Nome
                  v-text-field(
                    placeholder="Informe a nome do modelo",
                    v-model="modelo.nome",
                    :disabled="visualizeMode",
                    counter="200",
                    outlined,
                    dense,
                    required,
                    :rules="[rules.required, rules.min10, rules.max200]"
                  )

                  b Descrição
                  v-textarea(
                    placeholder="Informe a descrição do modelo",
                    v-model="modelo.descricao",
                    :disabled="visualizeMode",
                    counter="500",
                    outlined,
                    dense,
                    required,
                    :rules="[rules.required, rules.min10]"
                  )

                  v-divider.mt-4.mb-4

                  v-row
                    v-col.pt-0.pb-1(cols="5", md="5")
                      b Data de início de execução

                    v-col.pt-0.pb-1(cols="5", md="5")
                      b Data limites de execução

                    v-col.pt-0.pb-1(cols="2", md="2")
                      span(:style="!modelo.execucao_unica && !visualizeMode ? 'color: #ccc': 'color: #000'")  Tentativas

                    v-col(cols="5", md="5")
                      v-row
                        v-col.pt-0.pb-0.d-flex(cols="7", md="7")
                          v-text-field(
                            v-model="datainicio",
                            outlined,
                            dense,
                            :disabled="visualizeMode",
                            persistent-hint,
                            maxlength="10",
                            required,
                            :rules="[rules.required, rules.datevalid, rules.exact10]",
                            type="date"
                          )

                        v-col.pt-0.pb-0.d-flex(cols="5", md="5")
                          v-text-field(
                            v-model="horainicio",
                            outlined,
                            dense,
                            :disabled="visualizeMode",
                            persistent-hint,
                            maxlength="5",                            
                            required,
                            :rules="[rules.required, rules.hoursvalid, rules.exact5]",
                            type="time"
                          )

                    v-col(cols="5", md="5")
                      v-row
                        v-col.pt-0.pb-0.d-flex(cols="7", md="7")
                          v-text-field(
                            v-model="datafim",
                            outlined,
                            dense,
                            persistent-hint,
                            maxlength="10",
                            :disabled="visualizeMode",
                            required,
                            :min="new Date().toISOString().substr(0, 10)",
                            :rules="[rules.required, rules.datevalid, rules.exact10, rules.dateValidation]",
                            type="date"
                          )

                        v-col.pt-0.pb-0.d-flex(cols="5", md="5")
                          v-text-field(
                            v-model="horafim",
                            outlined,
                            dense,
                            :disabled="visualizeMode",
                            persistent-hint,
                            maxlength="5",
                            required,
                            :rules="[rules.required, rules.hoursvalid, rules.exact5]",
                            type="time"
                          )

                    v-col.pt-0.pb-0(cols="2", md="2")
                      v-text-field(
                        v-model="modelo.tentativas",
                        placeholder="Informe a quantidade de tentativas",
                        outlined,
                        reverse,
                        dense,
                        :disabled="!modelo.execucao_unica || visualizeMode",
                        type="number",
                        required,
                        :rules="[rules.required, rules.isPositive]"
                      )

                  v-divider.mt-4.mb-4

                  v-row
                    v-col.pt-0.pb-0(cols="3", md="3")
                      b Tipo de execução
                      v-radio-group(
                        v-model="modelo.execucao_unica",
                        :disabled="visualizeMode"
                      )
                        v-radio(label="Única", :value="true")

                        v-radio(label="Com intervalo", :value="false")
                          v-icon mdi mdi-chevron-down

                    v-col.pt-9.pb-0(cols="3", md="3")
                      span(
                        :style="{ color: modelo.execucao_unica ? '#ccc' : 'black' }"
                      ) Intervalo (a cada)
                      v-text-field(
                        v-model="modelo.intervalo",
                        placeholder="Informe o intervalo",
                        :disabled="modelo.execucao_unica || visualizeMode",
                        outlined,
                        reverse,
                        dense,
                        type="number",
                        :required="!modelo.execucao_unica",
                        :rules="[rules.required, rules.isPositive]"
                      )

                    v-col.pt-9.pb-0(cols="4", md="4")
                      span(
                        :style="{ color: modelo.execucao_unica ? '#ccc' : 'black' }"
                      ) Tipo do Intervalo
                      v-select(
                        placeholder="Informe o periodo",
                        v-model="modelo.tipo_intervalo",
                        :disabled="modelo.execucao_unica || visualizeMode",
                        outlined,
                        dense,
                        :items="selects.intervalos",
                        item-text="text",
                        item-value="value"
                      )

              v-col.pt-0.pb-0(cols="5", md="5")
                b Modelo do JSON
                .ma-0.pa-0(
                  style="border: 1px solid #e0e0e0; border-radius: 5px; height: calc(100vh - 260px); overflow-y: auto"
                )
                  ModeloJson(
                    v-model="modelo.modelo.fields",
                    :items="modeloJsonInicial.fields",
                    :disabled="visualizeMode"
                  )

      v-stepper-content.pa-0.pt-1(step="2")
        v-card(style="height: calc(100vh - 225px); overflow-y: auto")
          v-card-text.pb-0.pt-4
            v-row
              v-col.pt-0.pb-0(cols="12", md="12")
                v-tabs(v-model="activeTab")
                  v-tab(v-for="(sql, index) in sqls", :key="index" :class="!visualizeMode ? 'pl-8' : ''") 
                    | {{ sql.versao_minima != '' ? sql.versao_minima : 'Novo SQL'}}
                  v-spacer
                  v-btn(
                    v-if="!visualizeMode",
                    :disabled="progress.newSQL",
                    color="primary",
                    @click="setNewSQL",
                    outlined
                  ) 
                    v-icon mdi mdi-plus
                    | {{ progress.newSQL ? 'Criando SQL...' : 'Adicionar SQL' }}

                  v-tab-item(v-for="(row, index) in sqls", :key="index")
                    .mt-4(style="height: calc(100vh - 290px)")
                      v-row
                        v-col.pt-4.pb-0(cols="12", md="12")
                          div(v-if="versoesFiltered.length === 0") 
                            v-progress-linear(color="primary" indeterminate)
      
                          SQLEditor(
                            v-else
                            :index="index",
                            :dataset="row",
                            :versoes="versoesFiltered",
                            :disabled="visualizeMode",
                            :height="'calc(100vh - 390px)'"
                            :progress="progress"
                            @command="SQLcommand"
                          )

    v-stepper-items
      v-stepper-content.pa-0.pt-1(step="3")
        v-card(style="height: calc(100vh - 225px); overflow-y: auto")
          v-card-text.pb-0.pt-4
            v-row
              v-col.pt-0.pb-0(cols="3", md="3")
                span(:style="!visualizeMode ? 'color: #000' : 'color: #ccc'") Caracterização
                v-autocomplete(
                  v-model="filtros.listatipoempresa",
                  placeholder="< T O D O S >",
                  persistent-placedholder,
                  :items="selects.tipoempresa",
                  return-object,
                  item-text="value",
                  item-value="id",
                  outlined,
                  dense,
                  :disabled="visualizeMode",
                  hide-details,
                  multiple,
                  @change="filtraDestinatariosEmpresa"
                )
                  template(v-slot:prepend-item)
                    v-list-item(@click="clearCaracterizacao")
                      v-list-item-content
                        v-list-item-title Todos (Sem seleção)

                  template(v-slot:selection="{ item, index }")
                    span(v-if="index === 0") {{ filtros.listatipoempresa.length }} Tipo(s) de Empresa(s)

              v-col.pt-0.pb-0(cols="3", md="3")
                span(:style="!visualizeMode ? 'color: #000' : 'color: #ccc'") Módulos
                v-autocomplete(
                  v-model="filtros.listamodulo",
                  placeholder="< T O D O S >",
                  :items="selects.modulos",
                  persistent-placedholder,
                  return-object,
                  item-text="value",
                  item-value="id",
                  outlined,
                  dense,
                  :disabled="visualizeMode",
                  hide-details,
                  multiple,
                  @change="filtraDestinatariosEmpresa"
                )
                  template(v-slot:prepend-item)
                    v-list-item(@click="clearModulo") 
                      v-list-item-content
                        v-list-item-title Todos (Sem seleção)

                  template(v-slot:selection="{ item, index }")
                    span(v-if="index === 0") {{ filtros.listamodulo.length }} Módulo(s)

              v-col.pt-0.pb-0(cols="3", md="3")
                span(:style="!visualizeMode ? 'color: #000' : 'color: #ccc'") Estados
                v-autocomplete(
                  v-model="filtros.listauf",
                  placeholder="< T O D O S >",
                  persistent-placedholder,
                  :items="selects.estados",
                  return-object,
                  item-text="value",
                  item-value="id",
                  outlined,
                  dense,
                  :disabled="visualizeMode",
                  hide-details,
                  multiple,
                  @change="filtraDestinatariosEmpresa"
                )
                  template(v-slot:prepend-item)
                    v-list-item(@click="clearEstado")
                      v-list-item-content
                        v-list-item-title Todos (Sem seleção)

                  template(v-slot:selection="{ item, index }")
                    span(v-if="index === 0") {{ filtros.listauf.length }} Estado(s)

              v-col.pt-0.pb-0(cols="3", md="3")
                span(:style="!visualizeMode ? 'color: #000' : 'color: #ccc'") Pesquisar
                v-text-field(
                  v-model="searchClientes",
                  prepend-inner-icon="mdi-magnify",
                  label="Informe um cliente, hash ou versão",
                  single-line,
                  hide-details,
                  :disabled="visualizeMode",
                  clearable,
                  outlined,
                  dense
                )
            
            v-row
              v-col.pt-2.pb-0(cols="12", md="12" align="end")
                span {{ clientes.length }} Cliente(s) selecionado(s)
                span(v-if="!visualizeMode")
                  span {{ ' - ' }}
                  a(@click="visibilidadeListaClientes(!isOnlyClientesSelected)") {{ !isOnlyClientesSelected ? 'Mostrar Selecionados' : 'Mostrar Todos' }}          

            v-divider.ml-0.mr-0.mt-8.mb-4.pl-0.pr-0
            v-row
              v-col.pt-0.pb-0(cols="12", md="12")
                v-data-table(
                  v-model="clientes",
                  :search="searchClientes",
                  :headers="headersClientes",
                  :items="visualizeMode || isOnlyClientesSelected ? clientes : datasetClientesFiltered",
                  :loading="datasetClientesFiltered.length == 0",
                  :show-select="!visualizeMode",
                  :single-select="false",
                  :checkbox-color="visualizeMode ? 'grey' : 'primary'",
                  :disable-pagination="visualizeMode",
                  :hide-default-footer="visualizeMode",
                  :items-per-page="-1",
                  :footer-props="propsFooter",
                  dense
                  outlined,
                  item-key="hash",
                  class="clientesTable"
                )
                  template(v-slot:item.uf="{ item }")
                    span(v-if="item.uf") {{ item.uf.join(", ") }}
                    span(v-else) -

                  template(v-slot:item.statusserver="{ item }")
                    v-tooltip(bottom)
                      template(v-slot:activator="{ on, attrs }")
                        v-chip(
                          v-on="on"
                          v-bind="attrs"
                          :color="item.statusserver ? 'success' : 'error'"
                          small
                        )
                      span {{ item.statusserver ? 'Conectado' : 'Desconectado' }}                    

    .d-flex.justify-end.mb-1.mr-4.mt-0
      v-spacer

      v-btn(color="primary", @click="backPage()", outlined) 
        v-icon(left) mdi mdi-close
        span Fechar

      v-btn.ml-2(
        v-if="!visualizeMode && step != 2",
        color="success",
        :disabled="progress.saveGlobal",
        outlined,
        @click="save"
      ) 
        v-icon(left) mdi mdi-check
        span {{ progress.saveGlobal ? 'Salvando...' : 'Salvar' }}

  v-dialog(v-model="dialogExcluirSQL" width="500")
    v-card
      v-card-title
        span Atenção
      v-card-text
        span Ao excluir um SQL, o mesmo não estará mais disponível para edição. Deseja continuar?
      v-card-actions(class="d-flex justify-end mr-0")        
        v-btn(color="error", outlined, @click="dialogExcluirSQL = false; progress.deleteSQL = false")
          span Cancelar
        v-btn(color="success", outlined, @click="deleteSQL")
          span Confirmar
</template>

<script>
import csapi from "@/api/csapi";
import { versoes as versoes_api } from "@/api";
import { mapMutations } from "vuex";
import { clientes as api } from "@/api";
import { socket, state } from '@/socket';

import ModeloJson from "@/components/ModeloJson";
import SQLEditor from "@/components/SqlEditor";

export default {
  components: {
    SQLEditor,
    ModeloJson,
  },
  data() {
    return {
      step: 1,
      totalSteps: 3,
      loaded: false,
      activeTab: 0,
      visualizeMode: false,
      salvarIsClicked: false,
      propriedadesRepetidas: false,
      versoes: [],
      versoesFiltered: [],
      modeloJsonInicial: {
        type: "array",
        fields: [],
      },
      progress: {
        saveGlobal: false,
        newSQL: false,
        saveSQL: false,
        deleteSQL: false,
      },
      datainicio: new Date(new Date().getTime() + (1 * 60 * 60 * 1000)).toISOString().substr(0, 10),
      horainicio: new Date(new Date().getTime() + (1 * 60 * 60 * 1000)).toLocaleTimeString().substr(0, 5),
      datafim: new Date(new Date().getTime() + (4 * 60 * 60 * 1000)).toISOString().substr(0, 10),
      horafim: new Date(new Date().getTime() + (4 * 60 * 60 * 1000)).toLocaleTimeString().substr(0, 5),
      modelo: {
        id: 0,
        nome: "",
        descricao: "",
        tentativas: "3",
        datahora_inicio: "",
        datahora_fim: "",
        execucao_unica: true,
        intervalo: "1",
        tipo_intervalo: "hours",
        modelo: {
          type: "array",
          fields: [],
        },
      },
      propsFooter: { 
        itemsPerPageOptions: [50, 100, 200, 300, 500, -1], 
        showCurrentPage: true, 
        showFirstLastPage: true, 
        itemsPerPageText: 'Itens por página' 
      },
      mostrarComponenteSQL: false,
      dialogExcluirSQL: false,
      sqls: [],
      clientes: [],
      clientesRecuperados: [],
      rules: {
        required: (value) => !!value || "Campo obrigatório",
        max200: (value) =>
          (value || "").length <= 200 || "Máximo de 200 caracteres",
        min10: (value) =>
          (value || "").length >= 10 || "Mínimo de 10 caracteres",
        exact10: (value) => (value || "").length == 10 || "Data inválida",
        exact5: (value) => (value || "").length == 5 || "Hora inválida",
        datevalid: (value) => {
          const date = new Date(value.split("/").reverse().join("-"));
          return (date instanceof Date && !isNaN(date)) || "Data inválida";
        },
        hoursvalid: (value) => {
          const date = new Date("1970-01-01T" + value + ":00");
          return (date instanceof Date && !isNaN(date)) || "Hora inválida";
        },
        isPositive: (value) => value > 0 || "Valor deve ser maior que 0",
        dateValidation(value) {
          // Verifica se a data selecionada é posterior à data atual
          if (!value) return 'Por favor, selecione uma data';

          const selectedDate = new Date(value);
          const currentDate = new Date();
          
          selectedDate.setHours(0, 0, 0, 0);
          currentDate.setHours(-24, 0, 0, 0);

          if (selectedDate < currentDate) {
            return 'Data deve ser maior ou igual a data atual';
          }

          return true;
        }
      },
      selects: {
        intervalos: [
          { text: "Hora(s)", value: "hours" },
          { text: "Dia(s)", value: "days" },
          { text: "Semana(s)", value: "weeks" },
          { text: "Mese(s)", value: "months" },
        ],
        estados: [
          { id: "AC", value: "Acre" },
          { id: "AL", value: "Alagoas" },
          { id: "AP", value: "Amapá" },
          { id: "AM", value: "Amazonas" },
          { id: "BA", value: "Bahia" },
          { id: "CE", value: "Ceará" },
          { id: "DF", value: "Distrito Federal" },
          { id: "ES", value: "Espírito Santo" },
          { id: "GO", value: "Goiás" },
          { id: "MA", value: "Maranhão" },
          { id: "MT", value: "Mato Grosso" },
          { id: "MS", value: "Mato Grosso do Sul" },
          { id: "MG", value: "Minas Gerais" },
          { id: "PA", value: "Pará" },
          { id: "PB", value: "Paraíba" },
          { id: "PR", value: "Paraná" },
          { id: "PE", value: "Pernambuco" },
          { id: "PI", value: "Piauí" },
          { id: "RJ", value: "Rio de Janeiro" },
          { id: "RN", value: "Rio Grande do Norte" },
          { id: "RS", value: "Rio Grande do Sul" },
          { id: "RO", value: "Rondônia" },
          { id: "RR", value: "Roraima" },
          { id: "SC", value: "Santa Catarina" },
          { id: "SP", value: "São Paulo" },
          { id: "SE", value: "Sergipe" },
          { id: "TO", value: "Tocantins" },
        ],
        modulos: [
          { id: 16, value: "Comercialização", field: "utilizacscomercializacao"  },
          { id: 4, value: "Contabilidade", field: "utilizacscontabilidade" },
          { id: 11, value: "Emissor", field: "utilizacsemissor" },
          { id: 5, value: "Escrita Fiscal", field: "utilizacsescritafiscal" },
          { id: 25, value: "Faturamento", field: "utilizacsfaturamento" },
          { id: 24, value: "Financeiro", field: "utilizacsfinanceiro" },
          { id: 17, value: "Lalur", field: "utilizacslalur" },
          { id: 22, value: "LCDPR", field: "utilizacslcdpr" },
          { id: 10, value: "Romaneio", field: "utilizacsromaneio" },
          { id: 14, value: "Tributação", field: "utilizacstributacao" },
          { id: 8, value: "CS Web", field: "utilizamodulocsweb" },
          { id: 13, value: "Lib. Supervisor",field: "utilizamodulolibsupervisor" },
          { id: 26, value: "Pesador", field: "utilizamodulopesador" },
        ],
        tipoempresa: [
          { id: 1, value: "Armazém Geral" },
          { id: 2, value: "Revenda e Representação Comercial" },
          { id: 3, value: "Cooperativa" },
          { id: 4, value: "Condomínio Rural" },
          { id: 5, value: "Produtor Rural" },
          { id: 6, value: "Cerealista e Comercialização" },
          { id: 7, value: "Agroindústria" },
          { id: 8, value: "Peças e Agropecuária" },
          { id: 9, value: "Unidade de Recebimento de Embalagens" },
          { id: 99, value: "Financeiro" },
        ],
      },
      filtros: {
        listatipoempresa: [],
        listamodulo: [],
        listauf: [],
        listacliente: [],
      },
      headersClientes: [
        { text: "ID", value: "idcliente", width: '70'},
        { text: "Cliente", value: "nome" },
        { text: "Hash", value: "hash" },
        { text: "Versão", value: "versao" },
        { text: "Estados", value: "uf" },
        { text: 'Status CS Server', value: 'statusserver', width: '110', align: 'center' },
      ],
      datasetClientes: [],
      datasetClientesFiltered: [],
      isOnlyClientesSelected: false,
      searchClientes: "",
    };
  },

  watch: {
    datasetClientes() {
      this.clientes = this.datasetClientes.filter((item) =>
        this.clientesRecuperados.includes(item.hash)
      );
    },
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    backPage() {
      this.$router.back();
    },

    toISODate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    toLocaleDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    visibilidadeListaClientes(value) {
      this.isOnlyClientesSelected = value;
      this.searchClientes = "";
    },

    validaPropriedadesRepetidas(json) {
      json.fields.forEach((item) => {
        if (json.fields.filter((field) => field.name == item.name).length > 1) {
          this.propriedadesRepetidas = true;
        }

        if (item.type == "object" || item.type == "array") {
          this.validaPropriedadesRepetidas(item.fields);
        }
      });
    },

    validateCabecalho() {
      this.$refs.form.validate();

      if (this.modelo.execucao_unica == false) {
        if (!this.modelo.intervalo) {
          this.$toast.error("Informe o intervalo");
          return false;
        }
      }

      let dataInicio = new Date(`${this.datainicio}T${this.horainicio}:00Z`);
      dataInicio.setHours(dataInicio.getHours() + (new Date().getTimezoneOffset() / 60));

      let dataFim = new Date(`${this.datafim}T${this.horafim}:00Z`);
      dataFim.setHours(dataFim.getHours() + (new Date().getTimezoneOffset() / 60));
      
      let dataAtual = new Date();

      // // Validar se datae hora final é maior que data atual
      if (dataAtual >= dataFim) {
        this.$toast.error("Data final deve ser maior que a data atual");
        return false;
      }

      // Validar se datae hora final é maior que data inicial
      if (dataInicio >= dataFim) {
        this.$toast.error("Data final deve ser maior que a data inicial");
        return false;
      }

      return true;
    },

    validateSQLs(sql) {

      if (this.sqls.filter((item) => item.versao_minima == sql.versao_minima).length > 1) {
        this.$toast.error("SQLs: Já existe uma versão informada");
        return false;
      }

      if (!sql.sql || !sql.versao_minima) {
        this.$toast.error("SQLs: Todos os campos devem ser preenchidos");
        return false;
      }

      if (
        /(delete|drop|truncate|alter|update|insert|create)/i.test(sql.sql)
      ) {
        this.$toast.error("SQLs: Apenas consultas SELECT são permitidas");
        return false;
      }

      if (sql.sql == "" || sql.versao_minima == "") {
        this.$toast.error("SQLs: Todos os campos devem ser preenchidos");
        return false;
      }

      return true;
    },

    SQLcommand(action, value) {
      switch (action) {
        case 'save':
          this.setSql();
          break;
        case "delete":
          this.progress.deleteSQL = true;
          this.dialogExcluirSQL = true;
          break;
        default:
          break;
      }
    },

    save() {
      this.progress.saveGlobal = true;
      switch (this.step.toString()) {
        case "1":
          this.setCabecalho();
          break;
        case "3":
          this.setClientes();
          break;
        default:
          break;
      }
    },

    filtraDestinatariosEmpresa() {
      let copiaDataSet = [...this.datasetClientes];

      if (
        this.filtros.listatipoempresa.length == 0 &&
        this.filtros.listauf.length == 0 &&
        this.filtros.listamodulo.length == 0
      ) {
        this.datasetClientesFiltered = copiaDataSet;
        return false;
      }

      if (this.filtros.listatipoempresa.length > 0) {
        copiaDataSet = copiaDataSet.filter((item) => {
          return this.filtros.listatipoempresa
            .map((item2) => item2.id + " - " + item2.value)
            .includes(item.tipoempresa);
        });
      }

      if (this.filtros.listamodulo.length > 0) {
        copiaDataSet = copiaDataSet.filter((item) => {
          return this.filtros.listamodulo
            .map((item2) => item2.id)
            .some((item3) => {
              return item.modulos.includes(item3);
            });
        });
      }

      if (this.filtros.listauf.length > 0) {
        copiaDataSet = copiaDataSet.filter((item) => {
          return this.filtros.listauf
            .map((item2) => item2.id)
            .some((item3) => {
              return item.uf !== null ? item.uf.includes(item3) : [];
            });
        });
      }

      this.datasetClientesFiltered = copiaDataSet;
    },

    clearCaracterizacao() {
      this.filtros.listatipoempresa = [];
      this.filtraDestinatariosEmpresa();
    },

    clearModulo() {
      this.destinatariosFiltros.listamodulo = [];
      this.filtraDestinatariosEmpresa();
    },

    clearEstado() {
      this.filtros.listauf = [];
      this.filtraDestinatariosEmpresa();
    },

    async setCabecalho() {
      if (this.validateCabecalho() == false) {
        this.progress.saveGlobal = false;
        return;
      }

      this.showLoader("Salvando...");

      try {
        let res = {};

        this.modelo.tentativas = parseInt(this.modelo.tentativas);
        this.modelo.intervalo = parseInt(this.modelo.intervalo);
        let datahora_inicio = new Date(`${this.datainicio}T${this.horainicio}:00Z`);
        let datahora_fim = new Date(`${this.datafim}T${this.horafim}:00Z`);

        datahora_inicio.setHours(datahora_inicio.getHours() + (new Date().getTimezoneOffset() / 60));
        datahora_fim.setHours(datahora_fim.getHours() + (new Date().getTimezoneOffset() / 60));

        this.modelo.datahora_inicio = datahora_inicio.toISOString();
        this.modelo.datahora_fim = datahora_fim.toISOString();

        let data = JSON.parse(JSON.stringify(this.modelo));

        let link = this.$route.params.id == 0 ? "/v1/datahub" : `/v1/datahub/${this.$route.params.id}`;
        res = this.$route.params.id == 0 ? await csapi.post(link, data) : await csapi.put(link, data);

        if (res.status.toString().startsWith("2")) {
          this.$toast.success("Cabeçalho do modelo salvo com sucesso");
        } else {
          this.$toast.error("Ocorreu algum problema ao salvar as informações");
        }

        if (this.$route.params.id == 0) {
          this.modelo.id = res.data.id;
          this.$router.push(`/datahub/cadastro/${res.data.id}`);
        }

      } catch (e) {
        console.error("Response Error:", e.response);
        this.$toast.error("Ocorreu algum problema ao salvar as informações");
      } finally {
        this.progress.saveGlobal = false;
        this.hideLoader();
      }
    },

    async setSql() {
      const sql = this.sqls[this.activeTab];

      if (!this.validateSQLs(sql)) {
        return;
      }

      this.progress.saveSQL = true;
      try {
        const link = sql.id ? `/v1/datahub/${sql.datahub_id}/sqls/${sql.id}` : `/v1/datahub/${this.modelo.id}/sqls`;
        const method = sql.id ? 'put' : 'post';
        const { status } = await csapi[method](link, sql);

        if (!status.toString().startsWith("2")) {
          throw new Error('Ocorreu algum problema ao salvar as informações');
        }

        const action = sql.id ? 'atualizado' : 'criado';
        this.$toast.success(`SQLs para versão ${sql.versao_minima} ${action} com sucesso`);
      } catch (e) {
        console.error("Response Error:", e.response);
        this.$toast.error("Ocorreu algum problema ao salvar as informações");
      } finally {
        this.progress.saveSQL = false;
      }
    },

    async setNewSQL() {
      if (this.sqls.some(item => item.versao_minima === "")) {
        this.$toast.info("SQLs: Já existe uma aba para definição de SQL");
        return;
      }

      const newSQL = {
        versao_minima: "",
        sql: "",
      };

      try {
        this.progress.newSQL = true;
        const res = await csapi.post(`/v1/datahub/${this.modelo.id}/sqls`, newSQL);

        if (res.status.toString().startsWith("2")) {
          const resNovo = await csapi.get(`/v1/datahub/${this.modelo.id}/sqls`);
          const newItem = resNovo.data.find(item => item.versao_minima === '' && item.sql === '');

          if (newItem) {
            newSQL.id = newItem.id;
            newSQL.datahub_id = this.modelo.id;

            this.$toast.success("SQLs para versão criado com sucesso");
            this.sqls.push(newSQL);
            this.activeTab = this.sqls.length - 1;
          }
        }
      } catch (e) {
        console.error("Response Error:", e.response);
        this.$toast.error("Ocorreu algum problema ao salvar as informações");
      } finally {
        this.progress.newSQL = false;
      }
    },

    async setClientes() {
      this.progress.saveGlobal = true;
      try {
        const hashs = this.clientes.map((item) => item.hash);
        const { status } = await csapi.post(`/v1/datahub/${this.modelo.id}/clientes`, hashs);

        if (!status.toString().startsWith("2")) {
          throw new Error('Ocorreu algum problema ao salvar as informações');
        }

        this.$toast.success("Clientes salvo com sucesso");
      } catch (e) {
        console.error("Response Error:", e.response);
        this.$toast.error("Ocorreu algum problema ao salvar as informações");
      } finally {
        this.progress.saveGlobal = false;
      }
    },

    async getVersoesSistema() {
      versoes_api.refreshAuthProd(this.$store.state.auth);
      const _isProduction = process.env.NODE_ENV === 'production';
      let versoesServerFilter = [] 
      try {

        /* Inicio trecho de retrocompatibilidade para ambiente de produção */
        let data = await versoes_api.getDatahub();
        
        for (let x = 0; x < data.length; x++) {
          const versao = data[x].versao.split('.')

          // Ignorar versões menores que '2024.4.1.3'
          if (versao[0] < 2024) continue
          if (versao[0] == 2024 && versao[1] < 4) continue
          if (versao[0] == 2024 && versao[1] == 4 && versao[2] < 1) continue
          if (versao[0] == 2024 && versao[1] == 4 && versao[2] == 1 && versao[3] < 3) continue

          // Ignorar versões menores que '2024.4.1.3' e final '0' para ambiente de produção
          if (_isProduction && versao[3] == 0) continue
          
          versoesServerFilter.push(data[x])
        }
        /* Fim trecho de retrocompatibilidade para ambiente de produção */

        versoesServerFilter = versoesServerFilter.sort((a, b) => b.versao.localeCompare(a.versao))

        this.versoesFiltered = versoesServerFilter;
        this.versoes = versoesServerFilter;
      } catch (e) {
        this.$toast.error("Erro ao carregar versões do sistema");
      } finally {
        this.mostrarComponenteSQL = true;
      }
    },

    async getClientesSistema() {
      // Aqui vou recuperar os clientes do sistema
      try {
        api.refreshAuthProd(this.$store.state.auth);
        let data = await api.getAll();
        data = data
          .filter((item) => item.statusbase === 1)
          .filter((item) => item.hash != '49F07D1423B04144B169335FFF674F27')
          .sort((a, b) => { 
            if (a.nome != null && b.nome != null) return a.nome.localeCompare(b.nome)
          })

        // Tratamento de dados para deixar mais facil a manipulação
        data.forEach((cliente) => {
          if (cliente.uf) {
            cliente.uf = cliente.uf.split(";").map((item) => item.trim());
          }

          cliente.statusserver = false;
          cliente.modulos = [];

          cliente.empresas.forEach((empresa) => {
            if (empresa.modulos) {
              let keysModulo = Object.keys(empresa.modulos);
              let listaModulos = [];
              keysModulo.forEach((key) => {
                if (empresa.modulos[key] == "S") {
                  let idModulo = this.selects.modulos.find(
                    (item) => item.field == key
                  );
                  if (idModulo) listaModulos.push(idModulo.id);
                }
              });
              cliente.modulos = [...listaModulos];
            }
          });

          delete cliente.empresas;
        });

        // Verificar se o cliente está conectado no CS Server
        for (let i = 0; i < data.length; i++) {
          const index = state.socketConnections.findIndex((row) => data[i].hash === row.data.token.hash);
          data[i].statusserver = index !== -1;
        }

        this.datasetClientes = this.datasetClientesFiltered = data;

      } catch (e) {
        this.$toast.error("Erro ao carregar dados para uso do cadastro");
      }
    },

    async getCabecalho() {
      if (this.$route.params.id == 0) {
        return;
      }

      this.showLoader("Carregando Registro...");
      try {
        const res = await csapi.get(`/v1/datahub/${this.$route.params.id}`);

        if (res.status.toString().startsWith("2")) {
          if (!this.visualizeMode) {
            if (res.data.status != "PENDENTE") {
              this.$toast.error("O registro não permite mais edição");
              this.$router.back();
            }
          }

          res.data.execucao_unica =
            typeof res.data.execucao_unica == "number"
              ? res.data.execucao_unica == 1
              : res.data.execucao_unica;
          this.modeloJsonInicial.fields = res.data.modelo.fields;

          this.modelo = res.data;

          let dataInicio = new Date(this.modelo.datahora_inicio);
          let dataFim = new Date(this.modelo.datahora_fim);

          this.datainicio = dataInicio.toISOString().substr(0, 10);
          this.horainicio = dataInicio.toLocaleTimeString().substr(0, 5);
          this.datafim = dataFim.toISOString().substr(0, 10);
          this.horafim = dataFim.toLocaleTimeString().substr(0, 5);

          this.getSQLs();
          this.getClientes();
        } else {
          this.$toast.error("Ocorreu algum problema ao buscar as informações");
          return;
        }
      } catch (e) {
        if (e.response) {
          if (e.response.name == "DATAHUB_NOT_FOUND") {
            this.$toast.error("O registro não foi encontrado");
            this.$router.push(`/datahub`);
          }
        } else {
          console.error("Response Error:", e.response);
          this.$toast.error("Ocorreu algum problema ao buscar as informações");
        }
      } finally {
        this.hideLoader();
      }
    },

    async getSQLs() {
      try {
        const res = await csapi.get(`/v1/datahub/${this.modelo.id}/sqls`);

        if (res.status.toString().startsWith("2")) {
          if (res.data.length > 0) { 
            this.sqls = res.data.sort((a, b) => a.versao_minima.localeCompare(b.versao_minima));
          }
        } else {
          this.$toast.error("Ocorreu algum problema ao buscar as informações");
        }
      } catch (e) {
        this.$toast.error("Ocorreu algum problema ao buscar as informações");
      }
    },

    async getClientes() {
      try {
        const res = await csapi.get(
          `/v1/datahub/${this.modelo.id}/clientes?onlyhashs=true`
        );

        if (res.status.toString().startsWith("2")) {
          this.clientesRecuperados = res.data;
        } else {
          this.$toast.error("Ocorreu algum problema ao buscar as informações");
        }
      } catch (e) {
        this.$toast.error("Ocorreu algum problema ao buscar as informações");
      }
    },

    async deleteSQL() {
      try {
        this.progress.deleteSQL = true;

        if (!this.sqls[this.activeTab].id) {
          this.sqls.splice(this.activeTab, 1);
          this.dialogExcluirSQL = false;
          return;
        }

        const res = await csapi.delete(`/v1/datahub/${this.modelo.id}/sqls/${this.sqls[this.activeTab].id}`)

        if (res.status.toString().startsWith("2")) {
          this.$toast.success("SQL deletado com sucesso");
          this.sqls.splice(this.activeTab, 1);
        } else {
          this.$toast.error("Ocorreu algum problema ao deletar o SQL");
        }
      } catch (e) {
        this.$toast.error("Ocorreu algum problema ao deletar o SQL");
      } finally {
        this.dialogExcluirSQL = false;
        this.progress.deleteSQL = false;
      }
    },

    permissoesAcesso() {
      const roles = JSON.parse(JSON.stringify(this.$store.state.auth.user.roles));
      const role = roles.filter((role) => role.projectid == 257)

      if (role.length == 0) {
        this.$router.push(`/datahub`);
      } else {
        const permissao = role.filter((permission) => permission.id == 4)
        if (permissao.length == 0) {
          this.$router.push(`/datahub`);
        }
      }
    },
  },

  computed: {
    socketConnections() {
      for (let i = 0; i < this.clientes.length; i++) {
        const index = state.socketConnections.findIndex((row) => this.clientes[i].hash === row.data.token.hash);
        this.clientes[i].statusserver = index !== -1;
      }

      return state.socketConnections;
    },
  },

  created() {
    this.permissoesAcesso();
    if (this.$route.path.includes("visualizacao")) {
      this.visualizeMode = true;
    }
  },

  async mounted() {
    this.isControl(true);

    this.setTitle(
      Number(this.$route.params.id) > 0
        ? "DataHub - Alteração"
        : "DataHub - Inclusão"
    );
    if (this.visualizeMode) this.setTitle("DataHub - Visualização");

    // Carregamentos das API Gifnoc
    this.getVersoesSistema();
    this.getClientesSistema();

    // Carregamentos da API ControlSoft
    this.getCabecalho();

    socket.on('controlsoft:datahub:cadastro', (row) => {
      console.log('controlsoft:datahub:cadastro', row);
      if (row.data.token) {
        const item = this.datasetClientesFiltered.find(item => item.hash === row.data.token.hash);
        if (item) item.statusserver = true;
      }
    });
  },

  beforeDestroy() {
    socket.off('controlsoft:datahub:cadastro');
  },
};
</script>

<style>
.clientesTable .v-data-table__wrapper {
  height: calc(100vh - 410px);
  overflow-y: auto;
}
</style>